import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import useShopCartStore from '../../../hooks/useShopCartStore'
import { userRoleSelector, userSelector } from '../../../ducks/access/selectors';
import { updateShopCart } from '../../../services/api';
import { getUserCommerce, updateLocalStorageShopCartItems } from '../../../utils';
import { ROLE_ANONYMOUS, ROLE_COMMERCE, ROLE_PROMOTER } from '../../../constants';
import ConfirmationMessageModal from '../../confirmationMessageModal/ConfirmationMessageModal';

const ShopCartStore = ({children}) => {
    const { shopCart, loadBootItems, shopCartReset } = useShopCartStore()
    const [isFirstSyncItemsTime, setIsFirstSyncItemsTime] = useState(true)
    const shopCartTotalItems = shopCart?.items?.reduce((acc, item) => acc + item.quantity, 0)
    const user = useSelector(userSelector);
    const userRole = useSelector(userRoleSelector)
    
    const syncShopCartItemStorage = async ({user, items}) => {
        const userCommerce = getUserCommerce(user)

        if (userRole === ROLE_PROMOTER) {
            try {
                await updateShopCart({
                    items
                })
            } catch (error) {
                console.log("🚀 ~ onClickCart ~ error:", error)
            }
        }

        if (userRole === ROLE_COMMERCE) {
            try {
                updateLocalStorageShopCartItems(userCommerce.id, items)
            } catch (error) {
                console.log("🚀 ~ syncShopCartItemStorage ~ error:", error)
            }
        }
    }
    
    useEffect(() => {
        if ([ROLE_COMMERCE, ROLE_PROMOTER].includes(userRole)) {
            loadBootItems({ user })
        }
        if (userRole === ROLE_ANONYMOUS) {
            shopCartReset()
        }
    }, [user?.id])

    useEffect(() => {
        const syncShopCartItemStorageTimeOut = setTimeout(() => {
            if (shopCart.isLoaded && isFirstSyncItemsTime) {
                setIsFirstSyncItemsTime(false)
                return
            }
            if (
                shopCart.isLoaded || 
                [ROLE_COMMERCE, ROLE_PROMOTER].includes(userRole)
            ){
                syncShopCartItemStorage({ user, items: shopCart.items })
            }
        }, 1000);
        
        // eslint-disable-next-line consistent-return
        return () => {
            if (
                shopCart.isLoaded || 
                [ROLE_COMMERCE, ROLE_PROMOTER].includes(userRole)
            ){
                clearTimeout(syncShopCartItemStorageTimeOut)
            }
        }
      }, [shopCartTotalItems])

    return (
        <>
            <ConfirmationMessageModal />
            { children }
        </>
    )
}

export default ShopCartStore