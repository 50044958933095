/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, lazy, useEffect } from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import firebase from "firebase/app";
import "firebase/messaging";
import {
  selectedLocalitySelector,
  unregisterFlagSelector,
} from "../ducks/general/selectors";
import { userSelector } from "../ducks/access/selectors";
import SuspenseLoader from "../components/SuspenseLoader";
import InfoModal from "../components/InfoModal";
import Snackbar from "../components/Snackbar";
import getStoreOrdersInit from "../ducks/store/actions";
import { unregister } from "../serviceWorker";
import { setUnregisterFlag } from "../ducks/general/actions";
import { 
  ROLE_BRAND,
  ROLE_COMMERCE,
  ROLE_PROMOTER,
  ROLE_USER,
  ROLE_WAREHOUSE_WORKER, 
  rolesPaths
} from "../constants";

import { getUserRole } from "../utils"

const Home = lazy(() => import("./Home"));
const Login = lazy(() => import("./Login"));
const StoreDetail = lazy(() => import("./store/StoreDetail"));
const Register = lazy(() => import("./Register"));
const ContactInformation = lazy(() => import("./checkout/ContactInformation"));
const RecoverPassword = lazy(() => import("./RecoverPassword"));
const ChangePassword = lazy(() => import("./ChangePassword"));
const PurchaseHistory = lazy(() => import("./history/PurchaseHistory"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("./TermsAndConditions"));
const Catalog = lazy(() => import("./Catalog"));
const DetailOfAnOrder = lazy(() => import("./history/DetailOfAnOrder"));
const Profile = lazy(() => import("./profile/Profile"));
const CategoryView = lazy(() => import("./CategoryView"));
const StoreHome = lazy(() => import("./store/StoreHome"));
const StoreSettings = lazy(() => import("./store/StoreSettings"));
const StoreOrderDetails = lazy(() => import("./store/StoreOrderDetails"));
const StorageCommerce = lazy(() => import("./store/storageCommerceContainer/StorageCommerceContainer"));
const ChangePasswordProfile = lazy(() => import("./profile/ChangePassword"));
const WarehouseOrders = lazy(() => import("./warehouseOrders/WarehouseOrders"));
const WharehousersStore = lazy(() => import("./store/warehousers/Warehousers"))
const PromotersStore = lazy(() => import("./store/promoters/Promoters"))
const OrderEditing = lazy(() => import("./warehouse/orderEdit/OrderEdit"));
const OrderDetail = lazy(() => import("./warehouse/orderDetail/OrderDetail"));
const SalesShopOrders = lazy(() => import("./salesShopOrders/SalesShopOrders"));
const ConfirmationMessage = lazy(() => import("./message/ConfirmationMessage"));
// const WharehouserOrders = lazy(() => import("./warehouser/warehouserOrders/WharehouserOrders"));
// const Favorite = lazy(() => import("./profile/Favorite"));
// const StoreList = lazy(() => import("./store/StoreList"));
// const SearchResults = lazy(() => import("./SearchResults"));
// const ProductDetail = lazy(() => import("./store/ProductDetail"));


const Routes = ({ user }) => {
  const dispatch = useDispatch();
  const unregisterFlag = useSelector(unregisterFlagSelector);
  
  useEffect(() => {
    // console.log("unregistered flag", unregisterFlag);
    if (!unregisterFlag) {
      unregister();
      dispatch(setUnregisterFlag(true));
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    let unsubscribe;
    let messaging;
    if (firebase.messaging.isSupported()) {
      messaging = firebase.messaging();
    }
    if (messaging)
      unsubscribe = messaging.onMessage((payload) => {
        dispatch(getStoreOrdersInit(1, 10));
        if (navigator.serviceWorker && navigator.serviceWorker.controller)
          navigator.serviceWorker.controller.postMessage(payload);
      });

    return () => {
      if (messaging) unsubscribe();
    };
  }, [dispatch]);
  

  return (
    <Router>
      <Suspense fallback={<SuspenseLoader />}>
        <Switch>
          <RestrictedRoute
            exact
            path="/"
            component={Home}
            user={user}
          />
          <RestrictedRoute 
            exact 
            path="/home/:id?" 
            component={Home}
            user={user}
          />
          <RestrictedRoute 
            exact 
            path="/login" 
            component={Login} 
            user={user}
          />
          <RestrictedRoute 
            exact 
            path="/account-confirmation/:token" 
            component={Login} 
            user={user}
          />
          <RestrictedRoute 
            exact 
            path="/register" 
            component={Register} 
            user={user}
          />
          <RestrictedRoute 
            exact 
            path="/recover-password" 
            component={RecoverPassword} 
            user={user}
          />
          <RestrictedRoute
            exact
            path="/recovery-password/:token"
            component={ChangePassword}
            user={user}
          />
          <RestrictedRoute 
            exact 
            path="/privacy-policy" 
            component={PrivacyPolicy} 
            user={user}
          />
          <RestrictedRoute
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
            user={user}
          />
          <RestrictedRoute
            exact
            path="/catalog"
            component={Catalog}
            user={user}
          />
          <RestrictedRoute
            exact
            path="/store-detail/:id/:initialPage?"
            component={StoreDetail}
            user={user}
          />
          <RestrictedRoute
            exact
            path="/catalog/:initialPage/:idBrand?" // id --> initialPage
            component={Catalog}
            user={user}
          />
          <RestrictedRoute
            path="/category-view/:category/:subcategory?/:family?"
            component={CategoryView}
            user={user}
          />
          <RestrictedRoute
            exact
            path="/change-password"
            component={ChangePasswordProfile}
            user={user}
            allowedRoles={[
              ROLE_BRAND,
              ROLE_COMMERCE,
              ROLE_WAREHOUSE_WORKER,
              ROLE_PROMOTER,
              ROLE_USER
            ]}
          />
          <RestrictedRoute
            exact
            path="/profile"
            component={Profile}
            user={user}
            allowedRoles={[
              ROLE_USER
            ]}
          />
          <RestrictedRoute
            exact
            path="/purchase-history/:id"
            component={PurchaseHistory}
            user={user}
            allowedRoles={[
              ROLE_USER
            ]}
          />
          <RestrictedRoute
            exact
            path="/store/warehousers"
            component={WharehousersStore}
            user={user}
            allowedRoles={[
              ROLE_COMMERCE
            ]}
          />
          <RestrictedRoute
            exact
            path="/store/promoters"
            component={PromotersStore}
            user={user}
            allowedRoles={[
              ROLE_COMMERCE
            ]}
          />
          <RestrictedRoute
            exact
            path="/store-home/:initialPage?"
            component={StoreHome}
            user={user}
            allowedRoles={[
              ROLE_BRAND,
              ROLE_COMMERCE
            ]}
          />
          <RestrictedRoute
            exact
            path="/store-settings"
            component={StoreSettings}
            user={user}
            allowedRoles={[
              ROLE_COMMERCE
            ]}
          />
          <RestrictedRoute
            exact
            path="/store-order-details"
            component={StoreOrderDetails}
            user={user}
            allowedRoles={[
              ROLE_BRAND,
              ROLE_COMMERCE
            ]}
          />
          <RestrictedRoute
            exact
            path="/storage-commerce/:initialPage?"
            component={StorageCommerce}
            user={user}
            allowedRoles={[
              ROLE_COMMERCE,
              ROLE_PROMOTER
            ]}
          />
          <RestrictedRoute
            exact
            path="/warehouse/orders"
            component={WarehouseOrders}
            user={user}
            allowedRoles={[
              ROLE_WAREHOUSE_WORKER
            ]}
          />
          <RestrictedRoute
            exact
            path="/warehouse/orders/:id/edit"
            component={OrderEditing}
            user={user}
            allowedRoles={[
              ROLE_WAREHOUSE_WORKER
            ]}
          />
          <RestrictedRoute
            exact
            path="/warehouse/orders/:id/detail"
            component={OrderDetail}
            user={user}
            allowedRoles={[
              ROLE_WAREHOUSE_WORKER
            ]}
          />
          <RestrictedRoute
            exact
            path="/detail-order/:id"
            component={DetailOfAnOrder}
            user={user}
            allowedRoles={[
              ROLE_USER
            ]}
          />
          <RestrictedRoute
            exact
            path="/contact-information/"
            component={ContactInformation}
            user={user}
            allowedRoles={[
              ROLE_USER
            ]}
          />
          <RestrictedRoute
            exact
            path="/shop/sales"
            component={SalesShopOrders}
            user={user}
            allowedRoles={[
              ROLE_USER
            ]}
          />
         <Route
            exact
            path="/confirmation-message/:id?"
            component={ConfirmationMessage}
          />
          {/* <RestrictedRoute
            exact
            path="/order-details/:id"
            component={DetailOfAnOrder}
            isAuthenticated={isAuthenticated}
            hasSelectedLocality={hasSelectedLocality}
            privateRoute
            isCommerce={isCommerce}
            clientOnly
          /> */}
          {/* <Route 
            exact 
            path="/store-list" 
            component={StoreList} 
          /> */}
          {/* 
            <Route 
              exact 
              path="/product-detail/:id?" 
              component={ProductDetail} 
          /> */}
          {/* <Route 
            exact 
            path="/favorite" 
            component={Favorite} 
          /> */}
          {/* <RestrictedRoute
            exact
            path="/search-results"
            component={SearchResults}
            hasSelectedLocality={hasSelectedLocality}
            isAuthenticated={isAuthenticated}
            isCommerce={isCommerce}
            clientOnly
          /> */}
        </Switch>
        <InfoModal />
        <Snackbar />
      </Suspense>
    </Router>
  );
};


function mapStateToProps(state) {
  const hasSelectedLocality = selectedLocalitySelector(state);
  const user = userSelector(state);
  return {
    hasSelectedLocality:
      hasSelectedLocality !== null && hasSelectedLocality !== undefined,
    isAuthenticated:
      user !== undefined &&
      user !== null &&
      user.email !== undefined &&
      user.email !== null,
    isCommerce:
      user !== undefined &&
      user !== null &&
      user.commerce !== undefined &&
      user.commerce !== null,
    isWarehouser: 
      user &&
      Array.isArray(user.roles) &&
      user.roles.includes(ROLE_WAREHOUSE_WORKER),
    roles: user?.roles || [],
    user
  };
}
export default connect(mapStateToProps)(Routes);

const RestrictedRoute = ({
  component: Component,
  hasSelectedLocality,
  isAuthenticated,
  privateRoute,
  isCommerce,
  clientOnly,
  commerceOnly,
  withoutLocalityOnly,
  isWarehouser,
  warehouserOnly,
  roles,
  user,
  allowedRoles,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const matchPath = props.match.path;
        const currentRole = getUserRole(user);
        const rolePaths = rolesPaths.find(rolePaths => rolePaths.role === currentRole)

        if (rolePaths && !rolePaths.treePaths.includes(matchPath)) {
          return <Redirect exact to={rolePaths.forbiddenPathRedirection} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

RestrictedRoute.propTypes = {
  hasSelectedLocality: PropTypes.bool,
  privateRoute: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  clientOnly: PropTypes.bool,
  commerceOnly: PropTypes.bool,
  isCommerce: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.object.isRequired,
  withoutLocalityOnly: PropTypes.bool,
  isWarehouser: PropTypes.bool,
  warehouserOnly: PropTypes.bool
};

RestrictedRoute.defaultProps = {
  privateRoute: false,
  clientOnly: false,
  commerceOnly: false,
  isCommerce: false,
  hasSelectedLocality: false,
  withoutLocalityOnly: false,
  isWarehouser: false,
  warehouserOnly: false,
  isAuthenticated: false
};

// const UnloggedRoute = ({
//   component: Component,
//   isAuthenticated,
//   isProfileCompleted,
//   ...rest
// }) => (
//   <Route
//     {...rest}
//     render={(props) => {
//       if (isAuthenticated) {
//         if (rest.path === "/contact") {
//           return <Redirect exact to="/contact-authenticated" />;
//         }
//         if (isProfileCompleted && rest.path !== "/dashboard") {
//           return <Redirect to="/dashboard" {...props} />;
//         }
//         if (rest.path !== "/profile") {
//           return <Redirect exact to="/profile" />;
//         }
//         return <Component {...props} />;
//       }
//       return <Component {...props} />;
//     }}
//   />
// );